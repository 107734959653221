import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import registeringImage from '../../assets/photo/registering.png';
import createAccImage from '../../assets/photo/createAcc.png';
import { useTranslation } from 'react-i18next';

const RegisterPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const sectionsA = [
    {
      id: 1,
      title: t('guide.sign.Open the App'),
      description: t('guide.sign.Launch the app on your device. Visit arigus.xyz.'),
    },
    {
      id: 2,
      title: t('guide.sign.Navigate to the Sign-Up Tab'),
      description: t('guide.sign.Redirected . Sign-Up section'),
    },
    {
      id: 3,
      title: t('guide.sign.Enter Your Email'),
      description: t('guide.sign.Provide a valid email address. Ensure it’s accessible for verification'),
    },
    {
      id: 4,
      title: t('guide.sign.Create a Password'),
      description: t('guide.sign.Choose a secure password meeting the app’s requirements.'),
    },
    {
      id: 5,
      title: t('guide.sign.Verify Your Email'),
      description: t('guide.sign.Check your inbox for the verification code, and enter it in the app.'),
    },


  ];
  const sectionsB = [
    {
      id: 1,
      title: t('guide.sign.Open the App'),
      description: t('guide.sign.Launch the app on your device. Visit arigus.xyz.'),
    },
    {
      id: 2,
      title: t('guide.sign.Navigate to the Sign-In Tab'),
      description: t('guide.sign.Redirected to Sign-In section access the login form.'),
    },
    {
      id: 3,
      title: t('guide.sign.Enter Your Credentialsl'),
      description: t('guide.sign.Provide your registered email and password, then click Sign In'),
    },
  ]
  return (
    <Container
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: '100vh',
        ml: 6,
        mt: 3,
        borderRadius: 2,
        maxWidth: '90%'
      }}
    >

      {/* Section 2 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={createAccImage}
            alt="Create Account"
            style={{
              maxWidth: '90%',
              height: 'auto',
              borderRadius: '8px',
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: "95%", mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2, color: theme.palette.primary.main, }}>
            {t("guide.sign.Signing Up")}
          </Typography>
          <Typography
            variant="body1"
            paragraph
          >
            {t("guide.sign.To create an account and start using the app, follow these steps:")}
          </Typography>
          {sectionsA.map((section) => (
            <Typography variant="body1" key={section.id}>
              <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>{t(`${section.title}`)}</span>
              <span style={{ paddingLeft: '8px', marginBottom: '0.5rem', fontWeight: 'normal' }}>
                {t(`${section.description}`)}
              </span>
            </Typography>
          ))}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={registeringImage}
            alt="Registering"
            style={{
              maxWidth: '90%',
              height: 'auto',
            }}
          />
        </Box>

        <Box sx={{ flex: 2, maxWidth: "95%", mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2, color: theme.palette.primary.main, }}>
            {t("guide.sign.Signing In")}
          </Typography>
          <Typography
            variant="body1"
            paragraph
          >
            {t("guide.sign.If you already have an account, follow these steps to sign in:")}
          </Typography>

          {sectionsB.map((section) => (
            <Typography variant="body1" key={section.id}>
              <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>{t(`${section.title}`)}</span>
              <span style={{ paddingLeft: '8px', marginBottom: '0.5rem', fontWeight: 'normal' }}>
                {t(`${section.description}`)}
              </span>
            </Typography>
          ))}

        </Box>
      </Box>
    </Container >
  );
};

export default RegisterPage;
