import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';


const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: '10px 14px', // Adjust as needed
    backgroundColor: 'inherit', 
    transition: 'background-color 0.3s ease', // Smooth transition
  },
  '& .MuiInputLabel-root': {      
    lineHeight: 'normal', // Adjust label line height
  },
  '& .MuiInputBase-input::placeholder': {
    color: theme.palette.text.secondary,
    lineHeight: 'normal',       
  },
  '& .MuiInputBase-input:focus': {
    backgroundColor: theme.palette.background.paper, 
  },
}));

export default CustomTextField;



