import React, { createContext, useContext, useState } from 'react';

const BillingContext = createContext();

export const BillingProvider = ({ children }) => {
  const [payments, setPayments] = useState([
    { id: '1', description: 'Monthly Fee', amount: 50, status: 'unpaid' },
    { id: '2', description: 'Triple Fee', amount: 150, status: 'unpaid' },
    { id: '3', description: 'Half Year Fee', amount: 300, status: 'unpaid' },
  ]);

  // Function to toggle the selected state of a payment
  const selectPayment = (paymentId) => {
    setPayments((prevPayments) =>
      prevPayments.map((payment) =>
        payment.id === paymentId ? { ...payment, selected: !payment.selected } : payment
      )
    );
  };


  return (
    <BillingContext.Provider value={{ payments, selectPayment }}>
      {children}
    </BillingContext.Provider>
  );
};

export const useBilling = () => useContext(BillingContext);
