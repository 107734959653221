import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import registeringImage from '../../assets/photo/registering.png';
import createAccImage from '../../assets/photo/createAcc.png';

const QAPage = () => {
  const theme = useTheme();

  return (
    <Container
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: '100vh',
        ml:6,
        mt: 3,
        borderRadius: 2,
        maxWidth: '90%' 
      }}
    >
      {/* Title Section */}
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          color: theme.palette.primary.main,
          mb:2,
          fontSize: { xs: '1.2rem', sm: '1.3rem', md: '1.5rem' }, // Responsive font size
        }}
      >
        User Registration
      </Typography>

      {/* Section 1 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={registeringImage}
            alt="Registering"
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%' }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold' }}>
            How to Register
          </Typography>
          <Typography variant="body1" paragraph sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }}}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin facilisis nulla ac
            augue cursus, sit amet sollicitudin lorem gravida. Sed non augue et arcu posuere
            aliquet vel at risus. Donec eget neque id nulla feugiat tincidunt. Sed ut odio vel
            odio vestibulum iaculis.
            Vivamus nec risus erat. Suspendisse potenti. Nam tincidunt, purus et sollicitudin
            maximus, libero orci ullamcorper elit, eget ullamcorper elit odio sed felis.
          </Typography>
        </Box>
      </Box>

      {/* Section 2 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={createAccImage}
            alt="Create Account"
            style={{
              maxWidth: '100%',
              height: 'auto',
              borderRadius: '8px',
            }}
          />
        </Box>
        <Box sx={{ flex: 2 , maxWidth: '95%' }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold' }}>
            Creating Your Account
          </Typography>
          <Typography variant="body1" paragraph sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }}}>
            In this section, we will guide you through creating your account on our platform.
            First, you will need to fill out a registration form with basic information such as
            name, email, and a secure password. Once submitted, a confirmation email will be sent
            to you.
            After confirming your email address, you will be able to access all features of the
            platform. We ensure that your data is securely stored and protected throughout the
            process.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default QAPage;
