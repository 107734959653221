import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Select,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CircularProgress
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CustomTextField from './CustomTextField';
import { get, post, put } from 'aws-amplify/api';
import { fetchUserAttributes } from '@aws-amplify/auth';
import { useUserTrackers, refreshUserTrackers } from '../contexts/UserTrackersContext';
import { QRCodeCanvas } from 'qrcode.react';

const SERVICE_UUID = '8553dc1d-1db7-4cd3-868b-8a527460aa85';
const DEVICE_ID_UUID = '78563412-efcd-ab90-7856-341278563412';
const STOP_FOTA_UUID = '65870921-4365-8709-2143-658721436587';
const WRITE_START_HOURS_UUID = '65870921-4365-8709-2143-658721436688';

const ACTIVE = 0x55;
const PAUSED = 0x33;
const STOPED = 0xFF;

const DUE = 0x35;
const PAID = 0x85;
let bleService = null;

export const DeviceList = ({ onCancel }) => {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const { t } = useTranslation();
  const [newDeviceName, setNewDeviceName] = useState('');
  const [newDeviceId, setNewDeviceId] = useState('');
  const [idError, setIdError] = useState(false);
  const [idErrorMessage, setIdErrorMessage] = useState('');
  const [userId, setUserId] = useState('');
  const [loadingActivate, setLoadingActivate] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingPause, setLoadingPause] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [qrData, setQrData] = useState(null);
  const { userTrackers, refreshUserTrackers } = useUserTrackers();
  const [loadingBle, setLoadingBle] = useState(false);
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const pathCreateInvoice = '/qpay/create-invoice';


  useEffect(() => {
    getUserId();
  }, []);

  const handleCancel = () => {
    navigate('/');
  };

  const getUserId = async () => {
    try {
      const user = await fetchUserAttributes();
      setUserId(user.sub);
    } catch (error) {
      console.error('Error fetching user ID:', error);
    }
  };
  const connectToBleDevice = async () => {
    setLoadingBle(true);
    try {
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ name: 'ArigusV3' }],
        optionalServices: [SERVICE_UUID],
      });

      if (!device) {
        console.log('No device selected.');
        return;
      }

      console.log('Selected device:', device.name, device.id);
      const server = await device.gatt.connect();
      bleService = await server.getPrimaryService(SERVICE_UUID); // Store the service for later use

      if (!bleService) {
        console.error('Failed to get BLE service.');
        return;
      }

      // Read Device ID immediately upon connecting
      const deviceIdCharacteristic = await bleService.getCharacteristic(DEVICE_ID_UUID);
      const deviceIdValue = await deviceIdCharacteristic.readValue();
      const deviceID = new TextDecoder().decode(deviceIdValue);
      setNewDeviceId(deviceID);

    } catch (error) {
      handleError(error);
    } finally {
      setLoadingBle(false);
    }
  };

  const writeStartHoursToDevice = async (startHoursArray) => {
    try {
      if (startHoursArray.length >= 5) {
        const startHoursCharacteristic = await bleService.getCharacteristic(WRITE_START_HOURS_UUID);

        const first = startHoursArray[0];
        const last = startHoursArray[startHoursArray.length - 1];

        // Calculate 25% and 50% between first and last
        const point25 = Math.round((last - first) / 4);
        const point50 = Math.round((last - first) / 2);
        const point75 = point25 + point50;

        // Find the closest values in the array for these calculated points
        const closest25 = startHoursArray.reduce((prev, curr) =>
          Math.abs(curr - point25 + first) < Math.abs(prev - point25 + first) ? curr : prev
        );
        const closest50 = startHoursArray.reduce((prev, curr) =>
          Math.abs(curr - point50 + first) < Math.abs(prev - point50 + first) ? curr : prev
        );

        const closest75 = startHoursArray.reduce((prev, curr) =>
          Math.abs(curr - point75 + first) < Math.abs(prev - point75 + first) ? curr : prev
        );

        // Build the final array of 5 evenly spaced hours
        const selectedHours = [first, point25, point50, point75, last];

        // Convert to Uint8Array for BLE transmission
        const startHoursBuffer = new Uint8Array(selectedHours);

        await startHoursCharacteristic.writeValue(startHoursBuffer);
        console.log('Start hours written to the device successfully:', selectedHours);
      } else {
        console.error("Insufficient data: Need at least 4 elements in startHoursArray.");
      }
    } catch (error) {
      console.error('Error writing start hours to device:', error);
    }
  };



  const sendStopFotaCommand = async () => {
    try {
      const stopFotaCharacteristic = await bleService.getCharacteristic(STOP_FOTA_UUID);
      const command = new Uint8Array([0x01]);
      await stopFotaCharacteristic.writeValue(command);
      bleService = null;
      console.log('FOTA stopped successfully.');
    } catch (error) {
      console.error('Error stopping FOTA:', error);
    }
  };

  const handleError = (error) => {
    if (error instanceof DOMException) {
      console.error('Bluetooth connection failed:', error.message);
    } else {
      console.error('Unexpected error:', error);
      alert('An unexpected error occurred. Please try again.');
    }
  };
  const handleAddDevice = async () => {
    setLoadingAdd(true);
    try {
      // Fetch GPS coordinates from HERE API
      const hereResponse = await fetch(`https://geocode.search.hereapi.com/v1/geocode?q=${city}+${state}&apiKey=HQLbkVCB7VKkyfvcdaLIN0R_Q_oWyy-fLbGCl6wZkDM`);
      const hereData = await hereResponse.json();
      if (hereData.items.length === 0) {
        setIdError(true);
        setIdErrorMessage(t('device.Not found place.'));
        return;
      }

      const { lat, lng } = hereData.items[0].position;

      // Fetch satellite pass data from Astrocast API
      let responset;
      try {
        const restOperation = post({
          apiName: 'apiArigusService',
          path: '/satellitePasses',
          options: {
            body: {
              lat: lat,
              lng: lng
            },
            headers: {
              'Content-Type': 'application/json'
            }
          }
        });

        const { body } = await restOperation.response;
        responset = await body.json();
        await writeStartHoursToDevice(responset);
        await sendStopFotaCommand();

      } catch (error) {
        console.error('Error getting Ephemerides:', error);
        setIdError(true);
        setIdErrorMessage(t('device.Ephemeris not found.'));
        return;
      }

      // Proceed to add the device with the collected information
      try {
        const restOperation = post({
          apiName: 'apiArigusService',
          path: '/addTracker',
          options: {
            body: {
              userId: userId,
              trackerId: newDeviceId,
              nameTracker: newDeviceName,
              statusTracker: ACTIVE,
            },
          },
        });

        const { body } = await restOperation.response;
        await body.json();
      } catch (error) {
        console.error('Error adding tracker:', error);
        setIdError(true);
        setIdErrorMessage(t('device.Failed to register device.'));
        return;
      }

      // Refresh the trackers and reset form fields
      refreshUserTrackers();
      setNewDeviceName('');
      setNewDeviceId('');
      setCity('');
      setState('');
      setIdError(false);
      setIdErrorMessage('');

    } catch (error) {
      console.error('Unexpected error adding device:', error);
    } finally {
      setLoadingAdd(false);
    }
  };

  async function postReceipt(data) {
    try {
      const response = post({
        apiName: 'apiArigusService',
        path: '/ebarimtCreate',
        options: {
          body: data,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      });

      const { body } = await response.response;
      const responset = await body.json();
      console.log('Receipt posted:', responset);
      setQrData(responset.response.qrData);

    } catch (error) {
      console.error('Error posting receipt:', error);
    }
  }



  // Shared function to handle invoice creation
  const createInvoice = async (actionType) => {
    let senderInvoiceNo;
    if (actionType === ACTIVE) setLoadingActivate(true);
    if (actionType === PAUSED) setLoadingPause(true);

    try {
      const totalAmount = actionType === ACTIVE ? 300 : 120;
      const description = actionType === ACTIVE ? 'Activation invoice' : 'Total invoice for all payments';
      const invoiceNo = actionType === ACTIVE ? 'Active' : 'Pause';

      // Generate senderInvoiceNo
      const last8DeviceId = selectedDevice?.deviceId?.slice(-4);
      const randomSixDigits = Math.floor(1000 + Math.random() * 9000).toString();
      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, '0');
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const year = currentDate.getFullYear();
      senderInvoiceNo = `${invoiceNo}-${last8DeviceId}-${randomSixDigits}-${day}/${month}/${year}`;

      // Create QPay Invoice
      try {
        const restOperation = post({
          apiName: 'apiArigusService',
          path: pathCreateInvoice,
          options: {
            body: {
              senderInvoiceNo: senderInvoiceNo,
              invoiceReceiverCode: selectedDevice?.deviceId || 'RECEIVER_CODE',
              amount: totalAmount,
              description: description,
            },
          },
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        console.log('Qpay posted:', response);

        if (!response.invoice_id) {
          throw new Error('Failed to retrieve invoice ID.');
        }
        setQrCode(response.qr_image);

      } catch (error) {
        console.error(`Error creating ${actionType} invoice:`, error.message);
        setIdError(true);
        setIdErrorMessage(`Failed to create ${actionType} invoice. Please try again.`);
        return;
      }

      // Record invoice in QPay Billing
      try {
        const createdAt = Date.now();
        const billingOperation = post({
          apiName: 'apiArigusService',
          path: '/qpayBilling',
          options: {
            body: {
              userId: userId,
              trackerId: selectedDevice.deviceId,
              invoiceID: senderInvoiceNo,
              createdAt: createdAt,
              paymentMonths: 0,
              billingStatus: DUE,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          },
        });
        const billingResponse = await billingOperation.response;
        console.log('Billing response:', billingResponse);

      } catch (error) {
        console.error('Error recording invoice in QPay Billing:', error);
        setIdError(true);
        setIdErrorMessage('Failed to record invoice in billing. Please try again.');
        return;
      }

      // Post Receipt
      try {
        await postReceipt({
          "totalAmount": "110.00",
          "totalVAT": "10.00",
          // Add other fields as required
        });
      } catch (error) {
        console.error('Error posting receipt:', error);
        setIdError(true);
        setIdErrorMessage('Failed to post receipt. Please try again.');
        return;
      }

    } catch (error) {
      console.error('Unexpected error during invoice creation:', error);
      setIdError(true);
      setIdErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      if (actionType === ACTIVE) setLoadingActivate(false);
      if (actionType === PAUSED) setLoadingPause(false);
    }
  };

  return (
    <Container sx={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary, minHeight: '100%', ml: 8, mt: 2 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          color: theme.palette.primary.main,
          fontSize: { xs: '1.2rem', sm: '1.3rem', md: '1.5rem' }, // Responsive font size
        }}
      >
        {t("device.Device Management")}
      </Typography>
      <Box sx={{ mb: 3, mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={9} md={3}>
            <CustomTextField
              label={t("device.New Device Name")}
              variant="outlined"
              fullWidth
              value={newDeviceName}
              onChange={(e) => setNewDeviceName(e.target.value)}
            />
          </Grid>
          <Grid item xs={9} md={3}>
            <CustomTextField
              label={t("device.New Device ID")}
              variant="outlined"
              fullWidth
              value={newDeviceId}
              onChange={(e) => setNewDeviceId(e.target.value)}
              onClick={connectToBleDevice}
              error={idError}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={9} md={3}>
            <CustomTextField
              label={t("device.City")}
              variant="outlined"
              fullWidth
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Grid>
          <Grid item xs={9} md={3}>
            <CustomTextField
              label={t("device.State")}
              variant="outlined"
              fullWidth
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </Grid>
        </Grid>
        <Box>
          <Button
            variant="contained"
            onClick={handleAddDevice}
            disabled={loadingAdd}
            sx={{
              mr: 2, mt: 2, backgroundColor: theme.palette.primary.main,
              '&:hover': { backgroundColor: theme.palette.primary.dark },
              fontSize: '0.700rem', color: theme.palette.text.primary,
              borderRadius: '12px'
            }}
            size="small"
          >
            {loadingAdd ? <CircularProgress size={24} /> : t("device.Add Device")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancel}
            sx={{ mt: 2, fontSize: '0.700rem', color: theme.palette.text.primary, borderRadius: '12px' }}
            size="small"
          >
            {t("device.Cancel")}
          </Button>
        </Box>
        {idError && <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, color: theme.palette.text.warning, fontStyle: 'italic' }} >{idErrorMessage}</Typography>}
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h7" gutterBottom sx={{ color: theme.palette.text.secondary }}>
          {t("device.Select Device")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={9} md={4}>
            <Select
              fullWidth
              value={selectedDevice?.deviceId || ''}
              onChange={(event) => {
                const deviceId = event.target.value;
                const device = userTrackers.find((d) => d.deviceId === deviceId);
                setSelectedDevice(device);
                setQrCode(null); // Clear QR code when selecting a new device
              }}
              size="small"
            >
              {userTrackers.map((device) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.deviceName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Box>

      {selectedDevice && (
        <Box sx={{ mt: 4 }}>
          <Typography sx={{ color: theme.palette.text.secondary }}>{t("device.Device Name")}: {selectedDevice.deviceName}</Typography>
          <Typography sx={{ color: theme.palette.text.secondary }}>{t("device.Device ID")}: {selectedDevice.deviceId}</Typography>
          <Typography sx={{ color: theme.palette.text.secondary }}>{t("device.Paid for")}: {selectedDevice.billingMonth}</Typography>          
        </Box>
      )}
      <Box>
        <Button
          variant="contained"
          onClick={() => createInvoice(ACTIVE)}
          disabled={loadingActivate || !selectedDevice || selectedDevice.deviceStatus == ACTIVE}
          sx={{
            mr: 2, mt: 2, backgroundColor: theme.palette.primary.main,
            '&:hover': { backgroundColor: theme.palette.primary.dark },
            fontSize: '0.700rem', color: theme.palette.text.primary,
            borderRadius: '12px'
          }}
          size="small"
        >
          {loadingActivate ? <CircularProgress size={24} /> : t("device.Activate")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => createInvoice(PAUSED)}
          disabled={loadingPause || !selectedDevice || selectedDevice.deviceStatus == PAUSED}
          sx={{ mr: 2, mt: 2, fontSize: '0.700rem', color: theme.palette.text.primary, borderRadius: '12px' }}
          size="small"
        >
          {loadingPause ? <CircularProgress size={24} /> : t('billing.Pause')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCancel}
          disabled={loadingPause || !selectedDevice || selectedDevice.deviceStatus == STOPED}
          sx={{ mt: 2, fontSize: '0.700rem', color: theme.palette.text.primary, borderRadius: '12px' }}
          size="small"
        >
          {t("device.Deactivate")}
        </Button>
        <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, width:'full',color: theme.palette.text.warning, fontStyle: 'italic' }} >
          {t("device.You should pay according to your action on the billing menu.")}
        </Typography>
        {qrCode && (
          <Box sx={{ mt: 4 }}>
            <Card sx={{ display: 'inline-block', p: 2, maxWidth: 500, backgroundColor: 'transparent', boxShadow: 'none' }}>
              <CardContent sx={{ p: 0 }}>
                <Typography variant="h6" gutterBottom sx={{ color: theme.palette.primary.main }}>
                  {t("billing.Scan QR Code to Pay")}
                </Typography>
                <CardMedia
                  component="img"
                  image={`data:image/png;base64,${qrCode}`}
                  alt="QR Code"
                  sx={{ width: 200, height: 200, backgroundColor: 'transparent' }}
                />
                <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, color: theme.palette.text.warning, fontStyle: 'italic' }} >
                  {t("billing.Payment will be succeeded after QPay confirmation.")}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        )}
        {qrData && (
          <Box sx={{ mt: 4 }}>
            <Card sx={{ display: 'inline-block', p: 2, maxWidth: 500, backgroundColor: 'transparent', boxShadow: 'none' }}>
              <CardContent sx={{ p: 0 }}>
                <Typography variant="h6" gutterBottom sx={{ color: theme.palette.primary.main }}>
                  {t("billing.Scan QR Code to Pay")}
                </Typography>
                <QRCodeCanvas value={qrData} size={192} />
                <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, color: theme.palette.text.warning, fontStyle: 'italic' }} >
                  {t("billing.Payment will be succeeded after QPay confirmation.")}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        )}

      </Box>
    </Container>
  );
};

export default DeviceList;
