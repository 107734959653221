import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import markerSetImage from '../../assets/photo/position-01.png';
import bynameAccImage from '../../assets/photo/position-02.png';
import modeImage from '../../assets/photo/position-03.png';
import bySMSAccImage from '../../assets/photo/position-09.png';
import deleteImage from '../../assets/photo/position-04.png';
import saveImage from '../../assets/photo/position-05.png';
import { useTranslation } from 'react-i18next';


const MarkerPage = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  return (
    <Container
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: '100vh',
        ml: 6,
        mt: 3,
        borderRadius: 2,
        maxWidth: '90%'
      }}
    >

      {/* Section 1 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={markerSetImage}
            alt="Registering"
            style={{
              maxWidth: '90%',
              height: 'auto',
              borderRadius: '8px',
              objectFit: "contain",
              border: '1px solid darkgray', 
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%' }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold' }}>
            {t("guide.marker.Marker Default input")}
          </Typography>
          <Typography variant="body1" paragraph >
            {t("guide.marker.DefaultInput")}
          </Typography>
        </Box>
      </Box>

      {/* Section 2 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={bynameAccImage}
            alt="Create Account"
            style={{
              maxWidth: '90%',
              height: 'auto',
              borderRadius: '8px',
              objectFit: "contain",
              border: '1px solid darkgray', 
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%' }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold' }}>
            {t("guide.marker.Go to the place by name")}
          </Typography>
          <Typography variant="body1" paragraph >
            {t("guide.marker.byName")}            
          </Typography>
        </Box>
      </Box>
      {/* Section 2 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={bySMSAccImage}
            alt="Copy SMS to clipboard"
            style={{
              maxWidth: '90%',
              height: 'auto',
              borderRadius: '8px',
              objectFit: "contain",
              border: '1px solid darkgray', 
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%' }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold' }}>
            {t("guide.marker.Marker by SMS Location Message")}
          </Typography>
          <Typography variant="body1" paragraph >
            {t("guide.marker.markerSMS")}            
          </Typography>
        </Box>
      </Box>
      {/* Section 2 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={modeImage}
            alt="Copy SMS to clipboard"
            style={{
              maxWidth: '90%',
              height: 'auto',
              borderRadius: '8px',
              objectFit: "contain",
              border: '1px solid darkgray', 
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%' }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold' }}>
            {t("guide.marker.Change marker mode")}
          </Typography>
          <Typography variant="body1" paragraph >
            {t("guide.marker.modeChange")}            
          </Typography>
        </Box>
      </Box>
      {/* Section 2 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={saveImage}
            alt="Copy SMS to clipboard"
            style={{
              maxWidth: '90%',
              height: 'auto',
              borderRadius: '8px',
              objectFit: "contain",
              border: '1px solid darkgray', 
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%' }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold' }}>
            {t("guide.marker.Create custom marker")}
          </Typography>
          <Typography variant="body1" paragraph >
            {t("guide.marker.PinnedMarkers")}            
          </Typography>
        </Box>
      </Box>
      {/* Section 2 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={deleteImage}
            alt="Copy SMS to clipboard"
            style={{
              maxWidth: '90%',
              height: 'auto',
              borderRadius: '8px',
              border: '1px solid darkgray', 
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%' }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold' }}>
            {t("guide.marker.Deleting Pinned Markers")}
          </Typography>
          <Typography variant="body1" paragraph >
             {t("guide.marker.delete")} 
          </Typography>
        </Box>
      </Box>


    </Container>
  );
};

export default MarkerPage;
