import React from 'react';
import { Container, Typography, Box, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import menuImage from '../../assets/photo/map-01.png';
import positionImage from '../../assets/photo/position-08.png';
import homeImage from '../../assets/photo/position-07.png';
import distanceImage from '../../assets/photo/map-02.png';
import { useTranslation } from 'react-i18next';

const MapPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: '100vh',
        ml: 6,
        mt: 3,
        borderRadius: 2,
        maxWidth: '90%'
      }}
    >
      {/* Section 1 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={menuImage}
            alt="Registering"
            style={{
              maxWidth: '100%',
              height: 'auto',
              borderRadius: '8px',
              objectFit: "contain",             
              border: '1px solid darkgray'
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%' }}>
          <Box>
            {[
              // Group 1
              [
                { menu: "DeviceMenu", subMenu: "Device" },
                { menu: "MapMenu", subMenu: "Map" },
                { menu: "ReportMenu", subMenu: "Report" },
                { menu: "LocationMenu", subMenu: "Location" },
              ],
              // Group 2
              [
                { menu: "BillingMenu", subMenu: "Billing" },
                { menu: "TicketsMenu", subMenu: "Tickets" },
                { menu: "GuideMenu", subMenu: "Guide" },
              ],
              // Group 3
              [
                { menu: "ThemeMenu", subMenu: "Theme" },
                { menu: "SignOutMenu", subMenu: "SignOut" },
              ]
            ].map((group, groupIndex) => (
              <React.Fragment key={groupIndex}>
                {group.map(({ menu, subMenu }, itemIndex) => (
                  <Typography variant="body1" key={itemIndex}>
                    <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>{t(`guide.menu.${menu}`)}</span>
                    <span style={{ paddingLeft: '8px', marginBottom: '0.5rem', fontWeight: 'normal' }}>
                      {t(`guide.menu.${subMenu}`)}
                    </span>
                  </Typography>
                ))}
                {groupIndex < 3 && <Divider sx={{ my: 1 }} />} {/* Divider between groups */}
              </React.Fragment>
            ))}
          </Box>
        </Box>

      </Box>

      {/* Section 2 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={positionImage}
            alt="location"
            style={{
              maxWidth: '100%',
              height: 'auto',
              borderRadius: '8px',
              objectFit: "contain",
              border: '1px solid darkgray',                
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%' }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold' }}>
            {t("guide.Device List")}
          </Typography>
          <Typography variant="body1" paragraph >
            {t("guide.mapusageList")}
          </Typography>
        </Box>
      </Box>
      {/* Section 2 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={homeImage}
            alt="home"
            style={{
              maxWidth: '100%',
              height: 'auto',
              borderRadius: '8px',
              objectFit: "contain",
              border: '1px solid darkgray', 
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%' }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold' }}>
            {t('guide.Pinned Places')}
          </Typography>
          <Typography variant="body1" paragraph >
            {t('guide.PinnedPlaces')}

          </Typography>
        </Box>
      </Box>
      {/* Section 2 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={distanceImage}
            alt="distance"
            style={{
              maxWidth: '100%',
              height: 'auto',
              borderRadius: '8px',
              objectFit: "contain",             
              border: '1px solid darkgray', 
 
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%' }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold' }}>
            {t('guide.Sending Measured Distance and Direction')}
          </Typography>
          <Typography variant="body1" paragraph >
            {t('guide.sending distance')}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default MapPage;
