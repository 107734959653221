import React, { createContext, useContext, useState } from 'react';

const MarkerFlag = createContext();

export const MarkerFlagProvider = ({ children }) => {
    const [checked, setChecked] = useState(false);
    const [markerCoordinates, setMarkerCoordinates] = useState([]);

    return (
        <MarkerFlag.Provider value={{ 
            markerCoordinates, 
            setMarkerCoordinates, 
            checked, 
            setChecked 
        }}>
            {children}
        </MarkerFlag.Provider>
    );
};

export const useMarkerFlag = () => useContext(MarkerFlag);