import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import sms01 from '../../assets/photo/sim01.png';
import sms02 from '../../assets/photo/sim02.png';
import sms03 from '../../assets/photo/sim03.png';
import sms04 from '../../assets/photo/sim04.png';
import sms05 from '../../assets/photo/sim05.png';
import sms06 from '../../assets/photo/sim06.png';
import { useTranslation } from 'react-i18next';

const SmsPage = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const sections = [
        {
            imgSrc: sms01,
            text: t('guide.sms.1. Start SMS section on his mobile phone. 2 choose addition serice'),
        },
        {
            imgSrc: sms02,
            text: t('guide.sms.3. Colleague confirms on location which is acquired by mobiles gps or other location service. Press DONE'),
        },
        {
            imgSrc: sms03,
            text: t('guide.sms.4. He pressing longer on location data to get popup option menu.'),
        },
        {
            imgSrc: sms04,
            text: t('guide.sms.5. Then choose COPY'),
        },
        {
            imgSrc: sms05,
            text: t('guide.sms.6. Pressing longer on writing empty field to popup option menu. 7. Choose PASTE.'),
        },
        {
            imgSrc: sms06,
            text: t('guide.sms.8. Pasted Location text is now ready to send to Arigus User. He will feedback you how is distance and direction.'),
        }
    ];
    return (
        <Container
            sx={{
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
                minHeight: '100vh',
                ml: 6,
                mt: 3,
                borderRadius: 2,
                maxWidth: '90%'
            }}
        >
            {/* Title Section */}
            <Typography
                variant="h5"
                gutterBottom
                sx={{
                    color: theme.palette.primary.main,
                    mb: 2
                }}
            >
                {t("guide.sms.Sending location to Arigus service")}
            </Typography>

            <Box sx={{ flex: 3, maxWidth: '95%' }}>
                <Typography variant="body1" paragraph >
                    {t("guide.sms.howworks")}
                    <span style={{ color: theme.palette.text.highlight }} >
                        {t("guide.sms.Here is an instruction how your colleague send his location so you can add marker.")} </span>
                </Typography>
            </Box>
            <Box sx={{ padding: theme.spacing(4) }}>
                {sections.map((section, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            mb: 4,
                        }}
                    >
                        <Box
                            sx={{
                                flex: 1,
                                mb: { xs: 2, sm: 0 },
                                display: 'flex',
                                justifyContent: 'left',
                            }}
                        >
                            <img
                                src={section.imgSrc}
                                alt={`Section ${index + 1}`}
                                style={{
                                    maxWidth: '100%',
                                    height: 'auto',
                                }}
                            />
                        </Box>
                        <Box sx={{ flex: 3, maxWidth: '95%' }}>
                            <Typography variant="body1" paragraph>
                                {section.text}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Container>
    );
};

export default SmsPage;
